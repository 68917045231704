<template>
  <ul
    class="navigation-links align-items-center d-flex flex-column flex-md-row"
  >
    <li>
      <router-link to="/admin">Administrator</router-link>
    </li>
    <li>
      <a href="https://blog.symfonia.pl" target="_blank">Blog</a>
    </li>
    <li v-if="user" class="cursor-pointer" @click="logout()">
      <i class="fas fa-sign-out-alt"></i>
    </li>
  </ul>
</template>

<script>
export default {
  name: "NavigationLinks",
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem("user");
      window.location.reload();
    },
  },
};
</script>
