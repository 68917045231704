<template>
  <div class="container homepage-containers">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4">
        <router-link
          to="/course-book/user"
          class="homepage-container user d-flex flex-column align-items-center text-center"
        >
          <div class="icon mb-4">
            <img src="/img/icons/Icon_book.png" alt="" />
          </div>
          <h4>Podręcznik</h4>
          <p>
            Pomoże Ci rozpocząć pracę z systemem oraz krok po kroku poznawać
            wszystkie jego możliwości
          </p>
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <router-link
          to="/faq"
          class="homepage-container user d-flex flex-column align-items-center text-center"
        >
          <div class="icon mb-4">
            <img src="/img/icons/Icon_QA.png" alt="" />
          </div>
          <h4>FAQ</h4>
          <p>
            Zapoznaj się z najczęściej zadawanymi pytaniami. To dobry sposób na
            szybkie uzyskanie odpowiedzi.
          </p>
        </router-link>
      </div>
      <div class="col-12 col-md-6 col-lg-4">
        <router-link
          to="/news/410"
          class="homepage-container user d-flex flex-column align-items-center text-center"
        >
          <div class="icon mb-4">
            <img src="/img/icons/Icon_news.png" alt="" />
          </div>
          <h4>Aktualności</h4>
          <p>
            Tu znajdziesz wiadomości o zmianach i nowościach w systemie,
            zaproszenia na wydarzenia i informacje techniczne.
          </p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePageContainers",
};
</script>
