<template>
  <div
    id="footer"
    :style="`${$route.name === 'login' ? 'margin:0 !important' : ''}`"
  >
    <div class="container">
      <div class="row">
        <div
          class="justify-content-center justify-content-lg-start col-12 col-lg-4 d-flex align-items-end"
        >
          <a
            href="https://symfonia.pl/prywatnosc-i-cookies/"
            target="_blank"
            class="text-decoration-underline mr-4"
            >Polityka prywatności</a
          >
        </div>
        <div
          class="mt-4 mt-lg-0 col-12 col-lg-4 d-flex align-items-end justify-content-center"
        >
          Copyright © Symfonia 2023
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped lang="scss">
@import "src/assets/scss/colors";

#footer {
  background: $darkBlue2;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 50px;
  color: white;

  a {
    color: white;
  }
}
</style>
